import React from 'react'

const Auction = () => {
  return (
    <section className='component-auction mx-auto w-full max-w-screen-xl px-4 lg:px-6'>
      <div className='prose prose-lg prose-slate mx-auto mb-6 max-w-none text-center'>
        <h2>Our latest auctions</h2>
      </div>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.bidjs = {
              config: {
                clientId: '${process.env.GATSBY_BIDJS_CLIENT_ID}',
                googleMapsApiKey: '${process.env.GATSBY_GOOGLE_API_KEY}',
                region: '${process.env.GATSBY_BIDJS_REGION}',
                server: '${process.env.GATSBY_BIDJS_SERVER}',
              },
              options: {
                allowMetaDescriptionChange: true,
                allowTitleChange: true,
              },
              callback: function (event) {
                switch (event.action) {
                  case 'BIDJS_AUTHENTICATED':
                    if (window.toggleLoggedInState) window.toggleLoggedInState(true)
                  break
                  case 'BIDJS_AUTHENTICATION_REMOVED':
                    if (window.toggleLoggedInState) window.toggleLoggedInState(false)
                  break
                }
              }
            }
          `
        }}
      />
      <div id='bidlogix-legacy'>
        <div id='bidlogix-app' className='bidlogix-app bidjs-app' />
      </div>
      <div className='bidlogix-app bidjs-app'>
        <div id='bidlogix-modal' />
      </div>
      <div className='bidlogix-app'>
        <div id='bidjs' className='bidjs-app' />
      </div>
    </section>
  )
}

export default Auction
