import React from 'react'
import PropTypes from 'prop-types'
import Card from './card'

const CardDeck = ({ slice }) => {
  var numberOfCards = slice.items.length
  return (
    <section className={`component-${slice.slice_type} max-w-screen-xl mx-auto p-4`}>
      <ul className={`grid grid-cols-1 gap-4 ${numberOfCards > '2' ? 'md:grid-cols-3' : 'md:grid-cols-2'}`}>
        {slice.items.map((card, index) => {
          return <Card fields={card} key={`card-${index}`} />
        })}
      </ul>
    </section>
  )
}

CardDeck.propTypes = {
  slice: PropTypes.object.isRequired
}

export default CardDeck
