import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import CookieConsent from 'react-cookie-consent'
import Header from './header'
import Footer from './footer'

const layoutQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Layout = (props) => {
  return (
    <StaticQuery
      query={`${layoutQuery}`}
      render={(data) => (
        <div className='flex min-h-screen flex-col bg-gray-100 font-sans font-light text-gray-900 antialiased'>
          <Header siteTitle={data.site.siteMetadata.title} isLoggedIn={props.isLoggedIn} />
          <main role='main' id='main'>
            {props.children}
          </main>
          <Footer />
          <div id='fb-root'></div>
          <CookieConsent
            location='bottom'
            buttonText='Accept all cookies'
            enableDeclineButton
            declineButtonText='Decline'
            buttonClasses='rounded-sm border rounded text-brand-blue bg-white shadow px-4 py-2 transform duration-300 ease-in-out transition'
            declineButtonClasses='mx-1 px-4 py-2 border rounded'
            containerClasses='z-10 fixed inset-x-0 text-white bg-brand-blue p-3 text-center sm:flex justify-center'
            contentClasses='py-2'
            disableStyles
            cookieName='gatsby-gdpr-google-analytics'>
            We use cookies to collect information about how you use {data.site.siteMetadata.title}. We use this information to make the website work as well as possible and improve our services.
          </CookieConsent>
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageName: PropTypes.string,
  loggedIn: PropTypes.bool
}

export default Layout
