import React, { useEffect } from 'react'
import { SliceZone } from '@prismicio/react'
import PropTypes from 'prop-types'

// Components
import Accordion from './accordion'
import Auction from './auction'
import BannerWithCaption from './bannerWithCaption'
import CardDeck from './cardDeck'
import Contact from './contact'
import Hero from './hero'
import Image from './image'
import Map from './map'
import Quote from './quote'
import Text from './text'

const SliceList = ({ slices }) => {
  const components = {
    faq: Accordion,
    auction: Auction,
    banner_with_caption: BannerWithCaption,
    card_deck: CardDeck,
    contact: Contact,
    hero: Hero,
    image_gallery: Image,
    map: Map,
    quote: Quote,
    text: Text,
    text_with_button: Text
  }

  useEffect(() => {
    const script = document.createElement('script')
    script.src = `https://static.bidjs.com/${process.env.GATSBY_BIDJS_VERSION}/bootstrap3/js/bidjs-modules.chunk.js`
    script.async = true
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [])
  return <SliceZone slices={slices} components={components} defaultComponent={() => null} />
}

SliceList.propTypes = {
  slices: PropTypes.array.isRequired
}

export default SliceList
