import React, { useState, useEffect, useCallback, useRef } from 'react'
import { DotButton, PrevButton, NextButton } from './emblaCarouselButtons'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'

const EmblaCarousel = ({
  arrows,
  children,
  delayLength,
  dots,
  loop,
  slidesToScroll
}) => {
  const options = {
    loop: loop,
    slidesToScroll: slidesToScroll || 1,
    align: 'start',
    skipSnaps: false
  }
  const autoplay = useRef(
    Autoplay(
      { delay: delayLength, stopOnInteraction: false, stopOnMouseEnter: true },
      (emblaRoot) => emblaRoot.parentElement
    )
  )

  const [emblaRef, emblaApi] = useEmblaCarousel(options, [autoplay.current])
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState([])

  const scrollNext = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollNext()
    autoplay.current.reset()
  }, [emblaApi])

  const scrollPrev = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollPrev()
    autoplay.current.reset()
  }, [emblaApi])

  const scrollTo = useCallback(
    (index) => {
      if (!emblaApi) return
      emblaApi.scrollTo(index)
    },
    [emblaApi]
  )

  const onSelect = useCallback(() => {
    if (!emblaApi) return
    setSelectedIndex(emblaApi.selectedScrollSnap())
    setPrevBtnEnabled(emblaApi.canScrollPrev())
    setNextBtnEnabled(emblaApi.canScrollNext())
  }, [emblaApi, setSelectedIndex])

  useEffect(() => {
    if (!emblaApi) return
    onSelect()
    setScrollSnaps(emblaApi.scrollSnapList())
    emblaApi.on('select', onSelect)
  }, [emblaApi, onSelect, setScrollSnaps])

  return (
    <div className='embla relative w-full'>
      <div className='overflow-hidden' ref={emblaRef}>
        <div className='flex'>{children}</div>
      </div>
      {dots && (
        <div className='w-full text-center'>
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              selected={index === selectedIndex}
              onClick={() => scrollTo(index)}
            />
          ))}
        </div>
      )}
      {arrows && (
        <>
          <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
          <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </>
      )}
    </div>
  )
}

EmblaCarousel.defaultProps = {
  auto: true,
  delayLength: '3000'
}

export default EmblaCarousel
