import React, { useState, useEffect, useRef } from 'react'
import { Fade as Hamburger } from 'hamburger-react'
import { graphql, StaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import jwt_decode from 'jwt-decode'
import useOnClickOutside from './hooks/useOnClickOutside'

// Components
import Dropdown from '../components/dropdown'

const headerQuery = graphql`
  {
    prismicNavigation {
      ...PrismicNavigationFragment
    }
  }
`

const Header = (props) => {
  const ref = useRef()

  const [isAccountOpen, setAccountOpen] = useState(false)
  const [isLoggedIn, setLoggedIn] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [isToken, setToken] = useState(false)

  const accountToggle = () => setAccountOpen(!isAccountOpen)
  useOnClickOutside(ref, () => setOpen(false))

  useEffect(() => {
    setLoggedIn(props.isLoggedIn)
    setToken(localStorage.getItem(`bidJSToken${process.env.GATSBY_BIDJS_CLIENT_ID}`))
  }, [props.isLoggedIn])

  var decoded = false
  var admin = false
  // var vendor = false
  if (isToken) {
    decoded = jwt_decode(isToken)
    admin = decoded.adminLoginPermitted
    // vendor = decoded.publicVendorAdmin
  }
  return (
    <StaticQuery
      query={`${headerQuery}`}
      render={(data) => {
        const headerData = data.prismicNavigation.data
        return (
          <header role='banner' className='z-10'>
            <nav id='navigation' className='absolute z-20 w-full font-serif font-light shadow-md lg:fixed lg:flex lg:h-10 lg:flex-wrap lg:items-center lg:justify-between lg:bg-gray-800'>
              <span className='relative z-20 block lg:hidden'>
                <Hamburger color='#235e9d' label='Show menu' rounded toggled={isOpen} toggle={setOpen} />
              </span>
              <ul
                className={`${
                  !isOpen ? 'hidden' : 'absolute inset-0 flex h-screen flex-col justify-center rounded bg-black shadow-md'
                } mx-auto max-w-screen-xl bg-gray-100 text-2xl lg:relative lg:flex lg:flex-row lg:justify-end lg:bg-gray-800 lg:text-base lg:shadow-none`}>
                {headerData.nav.map((nav, index) => {
                  return (
                    <React.Fragment key={`nav-${index}`}>
                      {nav.items?.length > 1 ? (
                        <Dropdown items={nav.items}>{nav.primary.label}</Dropdown>
                      ) : (
                        <li key={`nav-${index}`} className='block'>
                          <a
                            className='flex w-full flex-row border-b p-5 uppercase transition duration-150 ease-in-out lg:border-b-0 lg:border-l lg:border-gray-700 lg:py-2 lg:px-10 lg:text-white'
                            href={nav.primary.link.url}>
                            {nav.primary.label}
                          </a>
                        </li>
                      )}
                    </React.Fragment>
                  )
                })}
                {!isLoggedIn ? (
                  <li className='block'>
                    <a
                      href='../auction/#!/login'
                      className='flex w-full flex-row border-b bg-brand-blue p-5 font-serif font-light uppercase text-white transition duration-150 ease-in-out hover:bg-brand-primary lg:border-b-0 lg:border-l  lg:border-gray-700 lg:py-2 lg:px-10 lg:text-white'
                      onClick={() => accountToggle()}>
                      Log In / Register
                    </a>
                  </li>
                ) : (
                  <li ref={ref} className='relative block'>
                    <button
                      aria-expanded={isAccountOpen}
                      aria-haspopup='true'
                      className='flex w-full items-center justify-between p-5 uppercase text-gray-800 transition duration-150 ease-linear hover:text-brand-blue lg:py-2 lg:text-white'
                      onClick={accountToggle}
                      onKeyDown={accountToggle}>
                      <div className='mr-2 rounded-full border border-gray-300 bg-brand-blue p-px'>
                        <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5 text-white' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path
                            strokeLinecap='round'
                            strokeLinejoin='round'
                            strokeWidth={1}
                            d='M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z'
                          />
                        </svg>
                      </div>
                      My Account
                      <div className='ml-1 mt-1 text-brand-blue'>
                        <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                          <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M19 9l-7 7-7-7' />
                        </svg>
                      </div>
                    </button>
                    {isAccountOpen && (
                      <ul className={`z-20 mt-3 bg-gray-100 px-3 xl:absolute xl:mt-0 xl:w-52 xl:bg-white xl:py-3 xl:shadow`}>
                        <li key={`settings${isLoggedIn}`}>
                          <a
                            href='../auction/#!/account'
                            className='block p-5 text-gray-800 transition duration-150 ease-linear hover:text-brand-blue focus:text-brand-primary lg:py-2'
                            onClick={() => accountToggle()}>
                            My Settings
                          </a>
                        </li>
                        <li key={`bids${isLoggedIn}`}>
                          <a
                            href='../auction/#!/myBids'
                            className='block p-5 text-gray-800 transition duration-150 ease-linear hover:text-brand-blue focus:text-brand-primary lg:py-2'
                            onClick={() => accountToggle()}>
                            My Bids
                          </a>
                        </li>
                        <li key={`invoice${isLoggedIn}`}>
                          <a
                            href='../auction/#!/invoices'
                            className='block p-5 text-gray-800 transition duration-150 ease-linear hover:text-brand-blue focus:text-brand-primary lg:py-2'
                            onClick={() => accountToggle()}>
                            My Invoices
                          </a>
                        </li>
                        <li key={`auctionsArchived${isLoggedIn}`}>
                          <a
                            className='block p-5 text-gray-800 transition duration-150 ease-linear hover:text-brand-blue focus:text-brand-primary lg:py-2'
                            href='../auction/#!/auctionsArchived'
                            onClick={() => accountToggle()}>
                            Archived Auctions
                          </a>
                        </li>
                        <li key={`search${isLoggedIn}`}>
                          <a
                            className='block p-5 text-gray-800 transition duration-150 ease-linear hover:text-brand-blue focus:text-brand-primary lg:py-2'
                            href='../auction/#!/search'
                            onClick={() => accountToggle()}>
                            Search Auctions
                          </a>
                        </li>
                        <li key={`admin${isLoggedIn}`} className={`${admin ? 'block' : 'hidden'}`}>
                          <a
                            href={`https://${process.env.GATSBY_BIDJS_SERVER}.${process.env.GATSBY_BIDJS_REGION}.${process.env.GATSBY_BIDJS_DOMAIN}/auction-mgt`}
                            className='clickable block p-5 text-gray-800 transition duration-150 ease-linear hover:text-brand-blue focus:text-brand-primary lg:py-2'
                            target='_blank'
                            rel='noopener noreferrer'
                            onClick={() => accountToggle()}>
                            Admin
                          </a>
                        </li>
                        <li key={`seperator${isLoggedIn}`} className='my-2 border-t' role='separator'></li>
                        <li key={`logout${isLoggedIn}`}>
                          <a
                            href='../auction/#!/logout'
                            className='flex items-center justify-between p-5 text-gray-800 transition duration-150 ease-linear hover:text-brand-blue lg:py-2'
                            onClick={() => accountToggle()}>
                            Log Out
                            <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5 text-gray-500' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1' />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    )}
                  </li>
                )}
              </ul>
            </nav>
            <div className='z-20 flex flex-shrink-0 items-center bg-white pt-10 text-gray-900'>
              <a href='/' className='mx-auto block'>
                <StaticImage width={390} src='../images/auctions-me.png' alt={`${headerData.company_display_name} logo`} placeholder='blurred' layout='constrained' />
              </a>
            </div>
          </header>
        )
      }}
    />
  )
}

export default Header
