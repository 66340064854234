import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

const BannerWithCaption = ({ slice }) => {
  return (
    <section className={`component-${slice.slice_type} max-w-screen-xl mx-auto p-6`}>
      <div className='relative'>
        <div className='h-56 sm:h-72 relative lg:absolute lg:inset-y-0 right-0 lg:h-full lg:w-1/2 overflow-hidden shadow-lg'>
          <GatsbyImage image={slice.primary.image.gatsbyImageData} alt={slice.primary.image.alt || ''} />
        </div>
        <div className='py-8 md:py-16 lg:max-w-2xl lg:w-1/2 lg:py-20'>
          <div className='lg:text-left text-center prose prose-lg min-w-full px-6'>
            <PrismicRichText field={slice.primary.title.richText} />
            <PrismicRichText field={slice.primary.text.richText} />
            <a className='button mt-3' href={slice.primary.button_link.url}>
              {slice.primary.button_text}
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

BannerWithCaption.propTypes = {
  slice: PropTypes.object.isRequired
}

export default BannerWithCaption
