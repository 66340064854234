import React from 'react'
import PropTypes from 'prop-types'
import { PrismicRichText } from '@prismicio/react'

const Text = ({ slice }) => {
  return (
    <section className={`component-${slice.slice_type} prose prose-lg prose-slate mx-auto max-w-screen-lg p-4`}>
      <PrismicRichText field={slice.primary.text.richText} />
      {slice.primary.button_link?.url && slice.primary.button_text && (
        <a className='button' href={slice.primary.button_link.url}>
          {slice.primary.button_text}
        </a>
      )}
    </section>
  )
}

Text.propTypes = {
  slice: PropTypes.object.isRequired
}

export default Text
