import React from 'react'

const Contact = ({ slice }) => {
  return (
    <section className={`component-${slice.slice_type} max-w-screen-xl mx-auto p-4`}>
      {slice.primary.text.html && <div className='prose prose-red max-w-screen-2xl col-span-2 lg:px-5 lg:border-r-2' dangerouslySetInnerHTML={{ __html: slice.primary.text.html }} />}
      <div className='col-span-3 lg:px-10'>
        <form name='contact' method='POST' action='/success' data-netlify='true' data-netlify-honeypot='surname'>
          <input type='hidden' name='surname' />
          <input type='hidden' name='form-name' value='contact' />
          <div className='mb-4'>
            <label className='block text-lg font-semibold mt-4 mb-2' htmlFor='name'>
              What's your name?
            </label>
            <input className='shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight' type='text' name='name' id='name' required />
          </div>
          <div className='mb-4'>
            <label className='block text-lg font-semibold mt-4 mb-2' htmlFor='email'>
              What's your email address?
            </label>
            <input className='shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight' type='email' name='email' id='email' required />
          </div>
          <div className='mb-4'>
            <label className='block text-lg font-semibold mt-4 mb-2' htmlFor='phone'>
              What's your phone number?
            </label>
            <input className='shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight placeholder-gray-500' type='tel' name='phone' id='phone' placeholder='Optional' />
          </div>
          <div className='mb-4'>
            <label className='block text-lg font-semibold mt-4 mb-2' htmlFor='message'>
              How can we help?
            </label>
            <textarea rows='4' className='shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight' name='message' id='message' required></textarea>
          </div>
          <div className='mb-4'>
            <button type='submit' className='button rounded w-full sm:w-auto'>
              Send your enquiry
            </button>
          </div>
        </form>
      </div>
    </section>
  )
}

export default Contact
