import * as React from 'react'
import { PrismicPreviewProvider, componentResolverFromMap } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from './src/utils/linkResolver'

import '@fontsource/frank-ruhl-libre'
import '@fontsource/roboto'
import './src/styles/default.css'

import PageTemplate from './src/templates/page'

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_MAIN_REPOSITORY_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          page: PageTemplate
        })
      }
    ]}>
    {element}
  </PrismicPreviewProvider>
)
