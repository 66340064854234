import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { PrismicRichText } from '@prismicio/react'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'
import SliceList from '../components/sliceList'

export const query = graphql`
  query Page($id: String) {
    prismicPage(id: { eq: $id }) {
      ...PrismicPageFragment
    }
  }
`

const PageTemplate = (props) => {
  const pageData = props.data.prismicPage.data
  const loggedIn = typeof window === 'undefined' ? false : localStorage.getItem(`bidJSToken${process.env.GATSBY_BIDJS_CLIENT_ID}`) !== null
  let images = false
  if (pageData.page_image?.gatsbyImageData) {
    images = withArtDirection(getImage(pageData.page_image.gatsbyImageData), [
      {
        media: '(max-width: 768px)',
        image: getImage(pageData.page_image.thumbnails.mobile.gatsbyImageData)
      }
    ])
  }

  return (
    <Layout isLoggedIn={loggedIn}>
      <Seo title={pageData.meta_title ? pageData.meta_title : pageData.title} keywords={pageData.meta_keywords} description={pageData.meta_description} lang={pageData.lang} />
      <div className='text-lg'>
        {pageData.page_title.text && (
          <div className='hero relative grid h-full grid-cols-1 grid-rows-1 lg:max-h-screen-1/2'>
            {pageData.page_image.gatsbyImageData && <GatsbyImage className='art-directed col-span-full row-span-full h-full mix-blend-multiply' image={images} alt={pageData.page_image.alt || ''} />}
            <div
              className={`${
                pageData.page_image.gatsbyImageData !== null && 'py-10 lg:pb-20 lg:pt-28'
              } relative col-span-full row-span-full mx-auto flex w-full max-w-screen-xl flex-col justify-center px-6 text-left`}>
              <div
                className={`${
                  pageData.page_image.gatsbyImageData !== null && 'max-w-5xl bg-white shadow-lg lg:ml-0 lg:w-1/2 lg:text-left '
                } text-brand-blue-lightest prose prose-lg mx-auto p-10 text-center`}>
                <PrismicRichText field={pageData.page_title.richText} />
                <PrismicRichText field={pageData.page_text.richText} />
                {pageData.page_button_link && pageData.page_button_text && (
                  <a className='button button-lg flex items-center' href={pageData.page_button_link.url}>
                    {pageData.page_button_text}
                  </a>
                )}
              </div>
            </div>
          </div>
        )}
        <div className='py-5'>
          <SliceList slices={pageData.body} />
        </div>
      </div>
    </Layout>
  )
}

export default withPrismicPreview(PageTemplate)
