import React, { PureComponent } from 'react'

class Dropdown extends PureComponent {
  constructor() {
    super()
    this.state = {
      subNavExpanded: false
    }
    this.toggleSubNav = this.toggleSubNav.bind(this)
  }
  container = React.createRef()
  toggleSubNav(id) {
    if (id === this.state.subNavExpandedId) {
      this.setState({
        subNavExpandedId: false
      })
    } else {
      this.setState({
        subNavExpandedId: id
      })
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside)
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }
  handleClickOutside = (event) => {
    if (this.container.current && !this.container.current.contains(event.target)) {
      this.setState({
        subNavExpandedId: false
      })
    }
  }

  render() {
    return (
      <li ref={this.container} className='m-2 text-black'>
        <button
          className='w-full md:w-auto lg:py-2 p-5 text-center text-xl text-white border-b-2 border-transparent hover:border-white hover:focus:border-white transition duration-150 ease-in-out flex items-center justify-center'
          aria-haspopup='true'
          aria-expanded={this.state.subNavExpandedId === this.props.children ? 'true' : 'false'}
          onClick={() => this.toggleSubNav(`${this.props.children}`)}
          onKeyDown={() => this.toggleSubNav(`${this.props.children}`)}>
          {this.props.children}
          <svg xmlns='http://www.w3.org/2000/svg' className='ml-1 w-5 -mr-5 md:ml-auto' height='1em' width='1em'>
            <path d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z' fill='currentColor' />
            <path d='M0 0h24v24H0z' fill='none' />
          </svg>
        </button>
        <ul className={`${this.state.subNavExpandedId === this.props.children ? 'md:absolute md:p-3 md:shadow-md md:rounded flex flex-col bg-white' : 'hidden'}`}>
          {this.props.items.map((subNav, index) => {
            return (
              <li className='m-2 md:mx-0' key={`subNav-${index}`}>
                <a
                  className='block lg:py-2 p-5 text-center md:text-left text-xl font-medium text-gray-600 hover:text-red-600 focus:outline-none focus:text-red-600 transition duration-150 ease-in-out'
                  href={subNav.subnav_link.url}>
                  {subNav.subnav_link_label}
                </a>
              </li>
            )
          })}
        </ul>
      </li>
    )
  }
}

export default Dropdown
