import React from 'react'
import PropTypes from 'prop-types'
import { PrismicRichText } from '@prismicio/react'

const Accordion = ({ slice }) => {
  return (
    <section className={`component-${slice.slice_type} max-w-screen-xl mx-auto p-4`}>
      {slice.primary.title && <PrismicRichText field={slice.primary.title.richText} />}
      {slice.primary.text && <PrismicRichText field={slice.primary.text.richText} />}
      <div className='mt-3'>
        {slice.items.map((accordion, index) => {
          return (
            <details className='bg-white rounded overflow-hidden shadow-sm transform transition-all mb-3' key={`accordion-${index}`}>
              <summary className='cursor-pointer bg-white hover:text-brand-primary m-0 flex justify-between w-full text-left p-4 outline-none'>
                <div>
                  <PrismicRichText field={accordion.question.richText} />
                </div>
                <div className='indicator flex items-center'>
                  <svg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24' fill='currentColor'>
                    <path d='M0 0h24v24H0V0z' fill='none' />
                    <path d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z' />
                  </svg>
                </div>
              </summary>
              <div className='text border-t p-4'>
                <PrismicRichText field={accordion.answer.richText} />
              </div>
            </details>
          )
        })}
      </div>
    </section>
  )
}

Accordion.propTypes = {
  slice: PropTypes.object.isRequired
}

export default Accordion
