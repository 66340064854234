import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

const Card = ({ fields }) => {
  return (
    <li className={`component-card flex flex-col flex-growrelative transform transition duration-150 ${fields.button_link.url && 'hover:scale-105 hover:shadow-lg'} ease-in-out m-2`}>
      {fields.image && (
        <div className='overflow-hidden transition duration-500 transform shadow-lg'>
          <GatsbyImage image={fields.image.gatsbyImageData} alt={fields.image.alt || ''} />
        </div>
      )}
      <div className='flex flex-col items-start flex-grow my-4 prose prose-lg px-6'>
        <PrismicRichText field={fields.title.richText} />
        <PrismicRichText field={fields.text.richText} />
        {fields.button_link.url && (
          <a className='button' href={fields.button_link.url}>
            {fields.button_text} <span className='inline-block transform transition duration-150 hover:translate-x-full'>→</span>
          </a>
        )}
      </div>
    </li>
  )
}

Card.propTypes = {
  fields: PropTypes.object.isRequired
}

export default Card
