import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

const Hero = ({ slice }) => {
  return (
    <div className={`component-${slice.slice_type} bg-white bg-cover bg-no-repeat overflow-hidden relative`}>
      <div className='container mx-auto md:flex flex-row'>
        <div className='content pt-20 w-full flex-1'>
          <div className='pb-8 sm:pb-16 md:pb-20 lg:pb-28 xl:pb-32 text-center lg:text-left'>
            <div className='mt-10 mx-auto px-6 sm:mt-12 md:mt-16 lg:mt-20 xl:mt-28'>
              <div className='font-black md:text-6xl text-5xl tracking-tight inline-block' itemProp='name'>
                {slice.primary.title.text}
              </div>
              {slice.primary.text && (
                <div className='sm:mx-auto lg:mx-0 my-4' itemProp='headline'>
                  <PrismicRichText field={slice.primary.text.richText} />
                </div>
              )}
              {slice.primary.button_link && slice.primary.button_text && (
                <a className='button mt-3' href={slice.primary.button_link.url}>
                  {slice.primary.button_text}
                </a>
              )}
            </div>
          </div>
        </div>
        {slice.primary.image && (
          <div className='flex-1'>
            <div className='polygon md:absolute right-0 top-0 bottom-0 md:w-1/2'>
              <GatsbyImage image={slice.primary.image.gatsbyImageData} alt={slice.primary.image.alt || ''} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

Hero.propTypes = {
  slice: PropTypes.object.isRequired,
  publishDate: PropTypes.string,
  author: PropTypes.object
}

Hero.defaultProps = {
  publishDate: null,
  author: null
}

export default Hero
