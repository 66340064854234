import React from 'react'
import PropTypes from 'prop-types'
import EmblaCarousel from './embla/emblaCarousel'
import { GatsbyImage } from 'gatsby-plugin-image'
import { PrismicRichText } from '@prismicio/react'

const Image = ({ slice }) => {
  return (
    <section className={`component-${slice.slice_type} max-w-screen-xl mx-auto p-6`}>
      {slice.primary.gallery_name.text && <div className='prose prose-xl text-center max-w-none mb-10' dangerouslySetInnerHTML={{ __html: slice.primary.gallery_name.html }} />}
      {slice.items.length > 1 ? (
        <EmblaCarousel autoplay={true} arrows={true} delayLength={4000} dots={true} loop={true}>
          {slice.items.map((item, index) => {
            return (
              <div className={`relative min-w-full ${'item' + (index === 0 ? ' active' : '')}`} key={`item-${index}`} aria-selected={index === 0 ? 'true' : 'false'}>
                {item.image && <GatsbyImage image={item.image.gatsbyImageData} alt={item.image.alt || ''} />}
                {item.text && (
                  <div className='caption relative z-10 py-3 px-6 w-full text-center'>
                    <PrismicRichText field={item.text.richText} />
                  </div>
                )}
              </div>
            )
          })}
        </EmblaCarousel>
      ) : (
        <div className='bg-white'>
          <GatsbyImage image={slice.items[0].image.gatsbyImageData} alt={slice.items[0].image.alt || ''} />
          {slice.items[0].image.text && (
            <div className='caption relative z-10 py-3 px-6 w-full text-center'>
              <PrismicRichText field={slice.items[0].image.text.richText} />
            </div>
          )}
        </div>
      )}
    </section>
  )
}

Image.propTypes = {
  slice: PropTypes.object.isRequired
}

export default Image
