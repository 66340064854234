import React from 'react'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'

const Social = ({ networks }) => {
  return (
    <div className='mt-4 flex space-x-3 md:order-2'>
      {networks.social.map((social, index) => {
        return (
          <a className='transform hover:scale-110 rounded-full' href={social.social_network_url.url} key={`social-${index}`}>
            {social.social_network.toLowerCase() === 'facebook' && <StaticImage src='../images/facebook.png' alt='Visit us on Facebook' placeholder='blurred' layout='fixed' width={32} />}
            {social.social_network.toLowerCase() === 'instagram' && <StaticImage src='../images/instagram.png' alt='Visit us on Instagram' placeholder='blurred' layout='fixed' width={32} />}
            {social.social_network.toLowerCase() === 'linkedin' && <StaticImage src='../images/linkedin.png' alt='Visit us on LinkedIn' placeholder='blurred' layout='fixed' width={32} />}
            {social.social_network.toLowerCase() === 'twitter' && <StaticImage src='../images/twitter.png' alt='Visit us on twitter' placeholder='blurred' layout='fixed' width={32} />}
          </a>
        )
      })}
    </div>
  )
}

Social.propTypes = {
  networks: PropTypes.object.isRequired
}

export default Social
