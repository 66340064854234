import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { StaticImage } from 'gatsby-plugin-image'

// Components
import Social from './social'

let newDate = new Date()
let year = newDate.getFullYear()

const footerQuery = graphql`
  {
    prismicNavigation {
      ...PrismicNavigationFragment
    }
    prismicSocial {
      ...PrismicSocialFragment
    }
  }
`

const Footer = () => {
  return (
    <StaticQuery
      query={`${footerQuery}`}
      render={(data) => {
        const footerData = data.prismicNavigation.data
        const socialData = data.prismicSocial?.data
        return (
          <footer role='contentinfo' className='mt-auto bg-gray-800 text-gray-100'>
            <div className='relative mx-auto max-w-screen-xl gap-0 overflow-hidden px-6 py-10 md:grid md:grid-cols-4 md:gap-6'>
              <div className='z-10 md:col-span-2'>
                <div className='mb-2 inline-block border-b border-gray-600 pb-2 uppercase text-gray-300'>{footerData.company_display_name}</div>
                <PrismicRichText field={footerData.company_address.richText} />
                {footerData.company_phone}
                {socialData && <Social networks={socialData} />}
              </div>
              <div className='absolute right-0 z-0 opacity-50'>
                <StaticImage width={390} src='../images/auctions-me_logo-transparent.png' alt={`${footerData.company_display_name} logo`} placeholder='blurred' layout='constrained' />
              </div>
              {footerData.body?.map((footer, index) => {
                return (
                  <div className='relative z-10' key={`footer-${index}`}>
                    {footer.primary.richText && (
                      <div className='mb-2 inline-block border-b border-gray-600 pb-2 uppercase text-gray-300'>
                        <PrismicRichText field={footer.primary.richText} />
                      </div>
                    )}
                    <ul>
                      {footer.items.map((footerItem, index) => {
                        return (
                          <li className='my-2' key={`footerItem-${index}`}>
                            <a className='py-2 hover:underline' href={footerItem.link.url}>
                              {footerItem.link_label}
                            </a>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                )
              })}
            </div>
            <div className='bg-gray-900 py-4 text-center text-gray-300'>
              <p className='text-center text-sm leading-6 text-gray-500'>
                Made by{' '}
                <a className='font-medium text-purple-400 hover:underline' href='https://www.thejuniperstudio.com/' target='_blank' rel='noopener noreferrer'>
                  The Juniper Studio
                </a>{' '}
                &copy; {year}. All rights reserved.
              </p>
            </div>
          </footer>
        )
      }}
    />
  )
}

export default Footer
